<template>
  <div v-html="html"></div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { orderDetail, balance_payments } from "network/igou2";

export default {
  name: "Pay",
  data() {
    return {
      order_id: this.$route.query.order_id,
      html: ""
    };
  },
  mounted() {
    if (!this.$route.query.order_id) {
      this.$toast.show("参数错误");
      this.$router.back();
      return;
    }
    this.fetchPay();
  },
  methods: {
    fetchPay() {
      pay(this.order_id).then(res => {
        this.html = res.data;
        this.$nextTick(() => {
          document.forms[0].submit(); //渲染支付宝支付页面
        });
      });
    },
    
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
</style>
